@layer components {

    .rating {
        @apply inline-flex items-center;
        &_box {
            @apply px-0.5 first:pl-0 last:pr-0;
        }
        &_box svg {
            @apply text-gray/25;
        }
        &_stars {
            @apply flex flex-row-reverse;
        }
        &--interactive &_box {
            @apply cursor-pointer;
        }
        &_box.is-active > svg,
        &_box.is-active ~ &_box > svg,
        &--interactive &_box:hover > svg,
        &--interactive &_box:hover ~ &_box > svg,
        &--interactive &_control:checked ~ &_box > svg {
            @apply text-orange;
        }
    }
}
