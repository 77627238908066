@layer components {
  .profile-menu {
    @apply flex col-span-full;
    @apply max-lg:gap-x-px;
    @apply lg:col-span-4 lg:flex-col lg:gap-y-4 lg:items-start lg-xl:col-span-3;
    &__item {
      @apply w-full max-lg:h-full;
    }
    &__link {
      @apply flex items-center px-1 py-4 text-fluid-xs-lg-10-16 font-semibold bg-white;
      @apply hover:text-color-orange hover:bg-[#FFEFE5];
      @apply max-lg:flex-col max-lg:justify-center max-lg:gap-y-2 max-lg:h-full max-lg:text-center;
      @apply lg:gap-x-6 lg:p-8 lg:text-2xl/tight lg:uppercase;
    }
    &__link.is-active {
      @apply text-color-orange bg-[#FFEFE5];
    }
  }
}
