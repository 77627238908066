@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/montserrat-vf.woff2') format('woff2 supports variations'),
        url('./../fonts/montserrat-vf.woff2') format('woff2-variations'),
        url('./../fonts/montserrat-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/montserrat-vf.woff2') format('woff2 supports variations'),
    url('./../fonts/montserrat-vf.woff2') format('woff2-variations'),
    url('./../fonts/montserrat-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/montserrat-vf.woff2') format('woff2 supports variations'),
        url('./../fonts/montserrat-vf.woff2') format('woff2-variations'),
        url('./../fonts/montserrat-semibold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./../fonts/montserrat-vf.woff2') format('woff2 supports variations'),
        url('./../fonts/montserrat-vf.woff2') format('woff2-variations'),
        url('./../fonts/montserrat-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./../fonts/nunitosans-regular.woff2') format('woff2'),
        url('./../fonts/nunitosans-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
