@layer components {
  .elfsight-api--footer-menu {
    .es-badge-container {
      @apply max-md:mr-0 md:ml-0 md:p-0 md:items-start;
    }
    .es-badge-sources-container {
      @apply md:-ml-1;
    }
  }


  /* .elfsight-api--index-reviews {
    .es-carousel-arrow-control {
      @apply rounded-none bg-color-light fill-color-dark duration-150 hover:bg-color-light hover:fill-color-gray/70;
    }
    .es-review-author-block-container {
      @apply -order-1 my-2;
    }
    .es-review-content-rating {
      @apply !mb-4;
    }
  } */
}




  /* .es-review-content-text {
    @apply text-justify text-color-gray;
  } */
  /* .elfsight-reviews .es-embed-root {
    @apply !max-w-full;
  }
  .elfsight-reviews .es-review-content {
    @apply text-sm/snug text-justify text-color-gray;
  }
  .elfsight-reviews .es-review-align-container {
    @apply max-w-lg mx-auto;
  }
  .elfsight-reviews .es-review-content-rating {
    @apply !mb-8;
  }
  .elfsight-reviews .es-review-author-block-container {
    @apply -order-1 mb-2;
  }
  .elfsight-reviews .es-review-source-source-bottom-container {
    @apply ml-auto mt-6;
  }
  .elfsight-reviews [role="button"] > div {
    @apply rounded-none bg-white fill-color-dark duration-150;
  }
  .elfsight-reviews [role="button"]:hover > div {
    @apply fill-color-gray/70;
  } */
