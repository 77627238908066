@layer components {

  ._btn {
    @apply inline-flex items-center justify-center gap-x-2.5;
    @apply border border-transparent;
    @apply px-4 py-0 min-h-14;
    @apply text-center text-xl/tight font-semibold uppercase font-sans;
    @apply transition duration-300 ease-in-out;
    @apply cursor-pointer;
  }



  .btn-orange {
    @apply _btn;
    @apply bg-color-orange text-white;
    &:hover {
      @apply bg-[#FF8D4C];
    }
  }
  .btn-light {
    @apply _btn;
    @apply bg-color-light text-color-dark;
    &:hover {
      @apply text-color-dark/70;
    }
  }
  .btn-light--text-gray {
    @apply _btn;
    @apply bg-color-light text-color-gray;
    &:hover {
      @apply text-color-gray/70;
    }
  }
  .btn-white {
    @apply _btn;
    @apply bg-white text-color-dark;
    &:hover {
      @apply text-color-dark/70;
    }
  }
  .btn-white--text-gray {
    @apply _btn;
    @apply bg-white text-color-gray;
    &:hover {
      @apply text-color-gray/70;
    }
  }
  .btn-gray {
    @apply _btn;
    @apply bg-color-gray text-white;
    &:hover {
      @apply bg-color-gray/70;
    }
  }
  .btn-dark {
    @apply _btn;
    @apply bg-color-dark text-white;
    &:hover {
      @apply bg-color-dark/70;
    }
  }
  .btn-black {
    @apply _btn;
    @apply bg-black text-white;
    &:hover {
      @apply bg-[#333333];
    }
    &:disabled {
      @apply bg-[#666666] text-[#CCCCCC] cursor-not-allowed opacity-60;
    }
  }



  .btn-outline-orange {
    @apply _btn;
    @apply border-color-orange text-color-orange;
  }
  .btn-outline-light {
    @apply _btn;
    @apply border-color-light text-color-light;
  }
  .btn-outline-gray {
    @apply _btn;
    @apply border-color-gray text-color-gray;
  }
  .btn-outline-dark {
    @apply _btn;
    @apply border-color-dark text-color-dark;
  }



  .btn-dropdown {
    @apply _btn;
    @apply border border-transparent bg-white;
    @apply w-full gap-x-4 px-3 min-h-8;
    @apply text-sm text-dark font-semibold justify-start normal-case;
    @apply after:content-dropdownIcon after:select-none after:ml-auto after:transition-transform after:duration-300 [&.is-open]:after:-rotate-180;
  }



  .btn-sm {
    @apply min-h-8 text-base/5;
  }
  .btn-md {
    @apply min-h-10 text-base/5;
  }
  .btn-lg {
    @apply min-h-12 text-base/5;
  }



  .btn-square {
    @apply p-0 min-h-[auto];
    @apply h-14 w-14;
  }
  .btn-square-sm {
    @apply btn-square;
    @apply h-8 w-8;
  }
  .btn-square-md {
    @apply btn-square;
    @apply h-10 w-10;
  }
  .btn-square-lg {
    @apply btn-square;
    @apply h-12 w-12;
  }

}
