.noUi-horizontal {
  @apply h-0.5;
}

.noUi-target {
  @apply bg-color-dark border-color-dark rounded-full shadow-none;
}

.noUi-connect {
  @apply bg-color-dark;
}

.noUi-base {
  @apply w-[calc(100%-14px)] left-[7px];
}

.noUi-horizontal .noUi-handle {
  @apply shadow-none rounded-full w-6 h-6 border-transparent bg-transparent cursor-auto;
  @apply before:w-4 before:h-4 before:border before:border-color-dark before:rounded-full before:inset-0 before:m-auto before:bg-white;
  @apply after:w-2.5 after:h-2.5 after:border after:border-color-dark after:rounded-full after:inset-0 after:m-auto after:bg-color-dark;
  @apply -right-3 top-1/2 -translate-y-1/2;
}
