:root {
  --hystmodal-speed: 0.3s;
  --hystmodal-zindex: 50;
}

.hystmodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 0px min-content 0px;
  justify-content: center;
  align-content: space-between;
  visibility: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hystmodal::-webkit-scrollbar {
  display: none;
}

.hystmodal::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
}

.hystmodal--animated::before {
  transition: opacity var(--hystmodal-speed) ease;
}

.hystmodal--active::before {
  opacity: 0.4;
}

.hystmodal--moved,
.hystmodal--active {
  opacity: 1;
  pointer-events: none;
  visibility: visible;
}

.hystmodal--active {
  pointer-events: auto;
}

.hystmodal__wrap {
  display: block;
  min-width: 0;
  grid-row: 2 / 3;
}

.hystmodal__window {
  min-width: 0;
  grid-row: 2 / 3;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-grow: 0;
  background: #fff;
  width: 100%;
  max-width: 600px;
  overflow: visible;
  transform: scale(0.95);
  opacity: 0;
  margin: 0 auto;
  padding: 40px;
}

.hystmodal__window--loading {
  min-height: 400px;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='105' height='105' viewBox='0 0 105 105' xmlns='http://www.w3.org/2000/svg' fill='%23aaa'%3E%3Ccircle cx='12.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='0s' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='12.5' cy='52.5' r='12.5' fill-opacity='.5'%3E%3Canimate attributeName='fill-opacity' begin='100ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='52.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='300ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='52.5' cy='52.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='600ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='92.5' cy='12.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='800ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='92.5' cy='52.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='400ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='12.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='700ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='52.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='500ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='92.5' cy='92.5' r='12.5'%3E%3Canimate attributeName='fill-opacity' begin='200ms' dur='1s' values='1;.2;1' calcMode='linear' repeatCount='indefinite' /%3E%3C/circle%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 48px;
}

.hystmodal--animated .hystmodal__window {
  transition: transform var(--hystmodal-speed) ease 0s, opacity var(--hystmodal-speed) ease 0s;
}

.hystmodal--active .hystmodal__window {
  transform: none;
  opacity: 1;
}
