@layer components {
  .hero-slider {
    .splide__pagination {
      @apply left-10 xs:left-[5%] lg:left-[10%];
      @apply absolute bottom-[7%] flex items-center gap-x-4;
      @apply *:flex;
    }
    .splide__pagination__page {
      @apply h-4 w-4 rounded-full bg-color-light/10 border border-white border-opacity-10;
    }
    .splide__pagination__page.is-active {
      @apply relative border-opacity-100;
      @apply after:absolute after:block after:w-2 after:h-2 after:inset-0 after:m-auto after:bg-white after:rounded-full;
    }
  }
}
