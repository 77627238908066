@layer components {
  .container {
    @apply max-w-[1756px] px-4 mx-auto;
  }

  .heading {
    @apply text-2xl text-dark font-semibold uppercase md:text-3xl lg:text-4xl;
  }

  .heading-sm {
    @apply text-base text-dark font-semibold uppercase md:text-lg lg:text-2xl;
  }

  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .product-offcanvas {
    @apply max-lg:fixed max-lg:left-0 max-lg:top-0 max-lg:h-dvh max-lg:w-dvw max-lg:will-change-transform max-lg:z-10;
    @apply lg:w-[332px] lg:!block lg:shrink-0;
  }

  .link {
    @apply text-color-gray underline underline-offset-2;
    &:hover {
      @apply no-underline;
    }
  }

  .filter-link {
    @apply flex items-center py-3 gap-x-4 text-sm/5 relative;
    &__checkbox {
      @apply w-4 h-4 flex items-center justify-center text-white overflow-hidden shrink-0;
      @apply border border-color-border/80;
    }
    &:not(.is-active) &__checkbox svg {
      @apply hidden;
    }
    &.is-active &__checkbox {
      @apply bg-orange border-color-orange;
    }
    &:hover &__checkbox {
      @apply border-color-orange;
    }
  }

  .wood-sample-category {
    @apply flex flex-col gap-y-4 relative;

    &:not(:nth-last-child(1)) {
      @apply after:absolute after:inset-x-0 after:-bottom-6 after:w-full after:h-px after:bg-color-border;
    }

    @screen xl {
      &:not(:nth-last-child(1):nth-child(even)):not(:nth-last-child(-n + 2):nth-child(odd)) {
        @apply after:absolute after:inset-x-0 after:-bottom-6 after:w-full after:h-px after:bg-color-border;
      }
      &:not(:nth-child(odd)) {
        @apply xl:before:absolute xl:before:top-0 xl:before:-left-6 xl:before:w-px xl:before:h-[calc(100%+1.5rem)] xl:before:bg-color-border;
      }
    }
  }

  .order-info-card-text {
    * {
      @apply font-sans text-sm text-color-dark font-medium;
    }
    table {
      @apply w-full;
    }
    thead, tbody {
      @apply hidden
    }
    tfoot {
      @apply grid grid-cols-[1fr,auto] items-center gap-y-2 gap-x-4;
    }
    tfoot tr {
      @apply grid grid-cols-subgrid col-span-full;
    }
    tfoot td {
      @apply !p-0 !border-none;
    }
    tfoot td:not(:first-child) {
      @apply text-right ;
    }
    tfoot tr:last-of-type b {
      @apply font-bold;

    }
  }



}
