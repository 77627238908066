.datepicker-showroom {
  --adp-background-color: theme('colors.white');
  --adp-border-color: theme('colors.color-border');
  --adp-color: theme('colors.color-dark');
  --adp-cell-background-color-selected: theme('colors.color-orange');
  --adp-cell-background-color-selected-hover: theme('colors.color-orange');
  --adp-color-current-date: theme('colors.color-orange');
  --adp-day-cell-height: 40px;
  --adp-day-cell-width: 1fr;
  --adp-day-name-color: #333333;
  --adp-border-radius: 12px;
  --adp-cell-border-radius: 50%;
  --adp-font-size: 14px;
  --adp-width: calc(100% - 34px);



  @screen xs {
    --adp-font-size: 16px;
  }

  @apply p-4 font-sans;

  .air-datepicker--pointer {
    @apply hidden;
  }
  .air-datepicker--navigation {
    @apply mb-4;
  }
  .air-datepicker-nav {
    @apply p-0 border-none justify-end;
  }
  .air-datepicker-nav--title {
    @apply order-first mr-auto text-[#333333] font-semibold pointer-events-none;
  }
  .air-datepicker-nav--action {
    @apply rounded-none;
  }
  .air-datepicker--content {
    @apply p-0;
  }
  .air-datepicker-body--day-names {
    @apply m-0 font-bold gap-1;
  }
  .air-datepicker-body--day-name {
    @apply w-9 h-9 xs:w-10 xs:h-10;
  }
  .air-datepicker-body--cells {
    @apply gap-0.5;
  }
  .air-datepicker-cell {
    @apply w-9 h-9 xs:w-10 xs:h-10;
    &.-current- {
      @apply font-bold;
    }
  }
}
