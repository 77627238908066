@layer components {

  /* TEXT FIELD */
  .text-field {
    @apply flex flex-col gap-y-2 w-full;

    .field-label {
      @apply text-color-dark text-sm/tight font-semibold font-sans md:text-base/tight;
      @apply has-[+.field-wrapper_:required]:after:content-['_*'] has-[+.field-wrapper_:required]:after:text-color-orange;
    }
    .field-wrapper {
      @apply relative;
    }
    input, textarea {
      @apply block;
      @apply border border-color-border bg-light;
      @apply text-color-dark text-base/tight font-normal font-sans;
      @apply p-0 px-4 h-14 w-full;
    }
    input.timepicker,
    input.datepicker {
      @apply pr-14;
    }
    input.timepicker + span,
    input.datepicker + span {
      @apply absolute inset-0 block pointer-events-none;
      @apply after:absolute after:right-4 after:top-1/2 after:-translate-y-1/2 after:w-6 after:h-6;
    }
    input.timepicker + span {
      @apply after:content-clockIcon;
    }
    input.datepicker + span {
      @apply after:content-calendarIcon;
    }
  }

  .text-field.text-field--password {
    input {
      @apply pr-14;
    }
  }

  .text-field.text-field--textarea {
    textarea {
      @apply py-4 min-h-20 resize-none;
    }
  }

  .text-field.is-invalid {
    @apply grid grid-cols-2;

    .field-wrapper {
      @apply col-span-2;
    }

    input, textarea {
      @apply border-[#FF0000];
    }

    .field-error {
      @apply col-start-2 row-start-1;
      @apply flex items-center justify-end text-right;
      @apply text-[#FF0000] text-xs/tight font-semibold font-sans;
    }
  }
  /* END TEXT FIELD */




  /* SELECT FIELD */
  .select-field {
    @apply flex flex-col gap-y-2 w-full;

    .field-label {
      @apply text-color-dark text-sm/tight font-semibold font-sans md:text-base/tight;
      @apply has-[+.field-wrapper_:required]:after:content-['_*'] has-[+.field-wrapper_:required]:after:text-color-orange;
    }
    .field-wrapper {
      @apply relative;
      @apply after:absolute after:top-1/2 after:right-4 after:-translate-y-1/2 after:pointer-events-none;
      @apply after:content-dropdownIcon;
    }

    select {
      @apply appearance-none;
      @apply border border-color-border bg-light;
      @apply text-color-dark text-base/tight font-normal font-sans;
      @apply py-0 pl-4 pr-8 h-14 w-full;
    }
  }

  .select-field.is-invalid {
    @apply grid grid-cols-2;

    .field-wrapper {
      @apply col-span-2;
    }

    select {
      @apply border-[#FF0000];
    }

    .field-error {
      @apply col-start-2 row-start-1;
      @apply flex items-center justify-end;
      @apply text-[#FF0000] text-xs/tight font-semibold font-sans;
    }
  }
  /* END SELECT FIELD */




  /* CHOICE FIELD */
  .choice-field {
    @apply grid grid-cols-[1.5rem,1fr] gap-x-2;

    input {
      @apply relative;
      @apply appearance-none w-6 h-6 border border-color-border bg-color-light;
      @apply hover:border-color-orange;
      @apply checked:after:absolute checked:after:inset-0 checked:after:w-3 checked:after:h-3 checked:after:m-auto checked:after:bg-color-orange;
    }

    .field-label {
      @apply text-color-dark text-base font-normal font-sans;
    }
  }

  .choice-field.is-invalid {
    input {
      @apply border-[#FF0000];
    }

    .field-label {
      @apply text-[#FF0000];
    }
  }
  /* END CHOICE FIELD */

}
